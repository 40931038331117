import { Box, Button, Flex, Tabs, Title } from '@mantine/core';
import { notifications } from '@mantine/notifications';
import { IconBasket, IconList } from '@tabler/icons-react';
import dayjs from 'dayjs';
import { DataTable } from 'mantine-datatable';
import React, { useEffect, useState } from 'react';
import { axiosInstance } from './../common/api';

const PAGE_SIZE = 50;

const Orders = () => {
  const [page, setPage] = useState(1);
  const [orderList, setOrderList] = useState({ items: [], pagination: { totalItems: 0 } });
  const [loading, setLoading] = useState(1);
  const [selectedRecords, setSelectedRecords] = useState([]);

  const [activeTab, setActiveTab] = useState('new'); // State to track the active tab
  const [allOrders, setAllOrders] = useState([]);
  const [filteredOrders, setFilteredOrders] = useState([]);

  const serverSideCount = null;
  const areButtonsEnabled = () => {
    return selectedRecords.length > 0 || serverSideCount !== null;
  };
  const finalCount = serverSideCount !== null ? serverSideCount : selectedRecords.length;

  useEffect(() => {
    setLoading(true);
    // @TODO: Products component starts from 0, change it here as well but fix it server-side first!
    const currPage = page - 1;
    axiosInstance
      .get(`/dev/orders/?page=${currPage}&limit=${PAGE_SIZE}`)
      .then((response) => {
        if (response.status === 200) {
          const items = response.data.data.items
            .map((item) => {
              return item.order_details.map((details) => {

                let status;
                if (details.status === 'shipped') {
                  status = 'Completed';
                } else if (item.order_status === 'paid') { 
                  status = 'New Order';
                }
                else {
                  status = 'Completed';
                }
                return {
                  id: item.id,
                  details_id: details.detail_id,
                  date_created: item.date_created,
                  sku: details.sku,
                  external_id: item.external_id,
                  parcel_no: details.parcel_no,
                  quantity: details.quantity,
                  url: item.url,
                  full_name: item.full_name,
                  order_status: status,
                };
              });
            })
            .flat();
          setAllOrders(items);
          setOrderList({ items, pagination: response.data.data.pagination });
        }
      })
      .catch((error) => {
        notifications.show({
          title: 'Error loading orders',
          message: error.message,
          color: 'red',
          autoClose: 10000,
        });
      })
      .finally(() => {
        setLoading(false);
      });
  }, [page]);

  useEffect(() => {
    const newFilteredOrders =
      activeTab === 'new' ? allOrders.filter((order) => order.order_status === 'New Order') : allOrders;

    setFilteredOrders(newFilteredOrders);
  }, [activeTab, allOrders]);

  const handlePrintButtonClick = async () => {
    setLoading(true);
    try {
      // Construct the URL based on the number of selected IDs
      const ids = selectedRecords.map((record) => record.id).join(',');
      const url = `orders/${ids}/print`;

      if (url.length > 1800) {
        alert('The selection is too large to process at once. Please select fewer items.');
        return;
      }

      // Make the GET request to download the PDF
      const response = await axiosInstance.get(`dev/${url}`);
      const downloadUrl = response.data.downloadUrl;
      window.open(downloadUrl, '_blank');
    } catch (error) {
      console.error('Error while printing labels:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleCompleteButtonClick = async () => {
    setLoading(true);
    try {
      const orderIds = selectedRecords.map((record) => record.id).join(',');
      console.log(selectedRecords);
      const payload = selectedRecords.map((record) => { return { details_id: record.details_id, status: 'shipped' } });
      const response = await axiosInstance.post(`dev/orders/${orderIds}/status`, payload);

      if (response.status === 200) {
        const updatedResponse = response.data.data.items;

        const updatedOrders = allOrders.map(order => {
          const detailUpdate = updatedResponse.find(u => u.details_id === order.details_id);
          if (detailUpdate) {
            return { ...order, order_status: 'Completed' }; // Update the status to 'Completed'
          }
          return order;
        });

        // Now update the state to reflect these changes in the UI
        setAllOrders(updatedOrders);
        
        // Also, update filteredOrders if needed, based on the activeTab
        const newFilteredOrders = activeTab === 'new' 
          ? updatedOrders.filter(order => order.order_status === 'New Order') 
          : updatedOrders;
        setFilteredOrders(newFilteredOrders);
      }
    } catch (error) {
      console.error('Error while updating order status:', error);
      notifications.show({
        title: 'Error updating orders',
        message: error.message,
        color: 'red',
        autoClose: 10000,
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box>
      <Title order={2} mb={32}>
        My Orders
      </Title>

      <Flex gap="md" justify="flex-end" align="center" direction="row" mb={-36}>
        <Button
          size="sm"
          variant="filled"
          color="dark.9"
          styles={{ root: { zIndex: 1000 } }}
          disabled={!areButtonsEnabled() || loading}
          onClick={handlePrintButtonClick}
        >
          Print labels
        </Button>
        <Button
          size="sm"
          variant="filled"
          color="dark.9"
          styles={{ root: { zIndex: 1000 } }}
          disabled={!areButtonsEnabled() || loading}
          onClick={handleCompleteButtonClick}
        >
          Mark ({finalCount}) completed
        </Button>
      </Flex>

      <Tabs variant="outline" value={activeTab} onChange={setActiveTab} mb={16}>
        <Tabs.List>
          <Tabs.Tab leftSection={<IconBasket />} value="new">
            New orders
          </Tabs.Tab>
          <Tabs.Tab leftSection={<IconList />} value="all">
            All
          </Tabs.Tab>
        </Tabs.List>
      </Tabs>

      <DataTable
        fetching={loading}
        withTableBorder
        withColumnBorders
        striped
        height={'100%'}
        style={{ minHeight: '60vh' }}
        columns={[
          {
            accessor: 'date_created',
            title: 'Date Created',
            render: ({ date_created }) => dayjs(date_created).format('MMM DD YYYY'),
          },
          { accessor: 'id', title: 'Order Id' },
          {
            accessor: 'sku',
            title: 'SKU',
          },
          { accessor: 'full_name', title: 'Client Name' },
          { accessor: 'quantity', title: 'Qty' },
          { accessor: 'parcel_no', title: 'Parcel no' },
          { accessor: 'order_status', title: 'Status' },
        ]}
        records={filteredOrders}
        recordsPerPage={PAGE_SIZE}
        page={page}
        totalRecords={orderList.pagination.totalItems}
        onPageChange={(p) => setPage(p)}
        idAccessor={({ id, sku }) => `${id}:${sku}`}
        selectedRecords={selectedRecords}
        onSelectedRecordsChange={setSelectedRecords}
      />
    </Box>
  );
};

export default Orders;
