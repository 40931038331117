import React, { useState } from 'react';
import { Input, Button, PasswordInput, Flex, Alert, LoadingOverlay, Box } from '@mantine/core';
import { IconAt, IconInfoCircle } from '@tabler/icons-react';
import { login } from '../common/api';

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);

  const [alert, setAlert] = useState({
    visible: false,
    title: 'Error',
    text: '',
  });

  const icon = <IconInfoCircle />;

  const handleLogin = async (e) => {
    e.preventDefault();

    try {
      setLoading(true);
      await login(
        JSON.stringify({
          username: email,
          password: password,
        })
      );
      window.location.reload();
    } catch (error) {
      const errorMessage = error.response.data || error.message;
      setAlert({
        visible: true,
        title: 'Error',
        text: errorMessage,
      });
      console.error('Error during login:', error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleCloseAlert = () => {
    setAlert({
      ...alert,
      visible: false,
    });
  };

  return (
    <Flex gap="xl" justify="center" align="center" direction="column" wrap="wrap" className="login">
      <LoadingOverlay visible={loading} zIndex={1000} overlayProps={{ radius: 'sm', blur: 1 }} />
      <Box style={{ marginTop: '-20%' }}>
        <img src="hw-partners-logo-dark.svg" alt="Hertwill logo" width="358" height="30" className="login__logo" />
      </Box>
      <form onSubmit={handleLogin}>
        <Flex
          w="100%"
          maw={250}
          miw={250}
          gap="sm"
          justify="flex-start"
          align="flex-start"
          direction="column"
          wrap="wrap"
        >
          <Input
            placeholder="Your email"
            leftSection={<IconAt size={16} />}
            value={email}
            onChange={(event) => setEmail(event.target.value)}
            style={{ width: '100%' }}
          />
          <PasswordInput
            placeholder="Password"
            value={password}
            style={{ width: '100%' }}
            onChange={(event) => setPassword(event.target.value)}
          />

          {alert.visible && (
            <Alert
              w="100%"
              variant="light"
              color="red"
              withCloseButton
              title={alert.title}
              icon={icon}
              onClose={handleCloseAlert}
            >
              {alert.text}
            </Alert>
          )}

          <Button color="dark.9" type="submit" variant="filled" style={{ width: '100%' }}>
            Login
          </Button>
        </Flex>
      </form>
    </Flex>
  );
};

export default Login;
