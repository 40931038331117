import axios from 'axios';
import { applyAuthTokenInterceptor, setAuthTokens, clearAuthTokens, getBrowserLocalStorage } from 'axios-jwt';
import { getApiBaseUrl } from './utils';

const BASE_URL = getApiBaseUrl();

// Create an axios instance that you wish to apply the interceptor to
// Always import this instance to make the calls!
export const axiosInstance = axios.create({ baseURL: BASE_URL });

// token refresh function.
const requestRefresh = (refresh) => {
  console.log('Refreshing token');
  return axios.post(`${BASE_URL}/dev/refresh-token`, { refreshToken: refresh }).then((response) => {
    console.log('Token refreshed');
    return response.data.token;
  });
};

// interceptor - useaxiosInstance instance!
applyAuthTokenInterceptor(axiosInstance, { requestRefresh });

export const login = async (params) => {
  const response = await axiosInstance.post('/dev/login', params);

  setAuthTokens({
    accessToken: response.data.token,
    refreshToken: response.data.refreshToken,
    username: JSON.parse(response.config.data)?.username,
  });
};

export const logout = () => {
  clearAuthTokens();
  window.location.replace('/');
};

export const getAuthUsername = () => {
  const storageKey = process.env.NODE_ENV === 'development' ? 'auth-tokens-development' : 'auth-tokens-production';
  const username = JSON.parse(getBrowserLocalStorage()._storage.getItem(storageKey))?.username ?? 'user@hw';

  return username;
};
