import { MantineProvider, createTheme } from '@mantine/core';
import { Route, Routes } from 'react-router-dom';
import '@mantine/core/styles.css';
import '@mantine/notifications/styles.css';
import '@mantine/dates/styles.css';
import './App.css';
import { Container } from '@mantine/core';
import { Notifications } from '@mantine/notifications';
import Login from './components/Login';
import Products from './components/Products';
import Orders from './components/Orders';
import Header from './components/Header';
import { isLoggedIn } from 'axios-jwt';

const theme = createTheme({
  // fontFamily: 'Poppins, sans-serif',
  colors: {
    hw: ['#f2f3f7', '#e3e4e8', '#c5c7d1', '#a3a7ba', '#878da7', '#757c9c', '#6c7498', '#5a6284', '#505778', '#434b6b'],
  },
  primaryShade: 9,
});

function App() {
  const loggedIn = isLoggedIn();
  return (
    <MantineProvider theme={theme}>
      <Notifications position="top-right" zIndex={1000} />
      {loggedIn ? (
        <div className="App">
          <Header />
          <Container size="xl" my={32}>
            <Routes>
              <Route path="/" element={<Orders />} />
              <Route path="/products" element={<Products />} />
            </Routes>
          </Container>
        </div>
      ) : (
        <Login />
      )}
    </MantineProvider>
  );
}

export default App;
