import React, { useEffect, useState } from 'react';
import { Box, Title, Group, ActionIcon, Center, Modal, Button, NumberInput, Table } from '@mantine/core';
import { IconEdit } from '@tabler/icons-react';
import { DataTable } from 'mantine-datatable';
import { notifications } from '@mantine/notifications';
import { axiosInstance } from './../common/api';

const PAGE_SIZE = 50;

const Products = () => {
  const [orderList, setOrderList] = useState({ items: [], pagination: { totalItems: 0 } });
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(1);

  const [stockChangeModal, setStockChangeModal] = useState({ open: false, data: null, loading: false });

  const showStockChangeModal = (data) => {
    const { options, variations, id } = data;
    const modalData = variations.length ? variations : [data];
    setStockChangeModal({ open: true, title: data.name, data: modalData, variations, options, id });
  };

  const handleStockChange = (newValue, index) => {
    const newData = [...stockChangeModal.data];
    newData[index] = { ...newData[index], stock: newValue };
    setStockChangeModal({ ...stockChangeModal, data: newData });
  };

  const buildStockChangeApiData = (product) => {
    if (product.variations.length > 0) {
      // Product with variations
      // { variations: [{ stock: 10, variationId: 100 }, {stock: 20, variationId: 200} ]}
      return { title: product.title, id: product.id, payload: { variations: product.data } };
    }
    // Simple product
    // { main: { stock: 10 } }
    return { title: product.title, id: product.id, payload: { main: { stock: product.data[0].stock } } };
  };

  const handleStockChangeSave = () => {
    const { id, payload, title } = buildStockChangeApiData(stockChangeModal);

    // console.log(`POST /dev/products/${id}`);
    // console.log(`Payload: ${JSON.stringify(payload)}`);

    setStockChangeModal({ ...stockChangeModal, loading: true });
    axiosInstance
      .post(`/dev/products/${id}`, payload)
      .then((response) => {
        validateStockChangeUpdate(response);
        notifications.show({
          title: title,
          message: `Quantities updated successfully`,
          color: 'green',
          autoClose: 10000,
        });
      })
      .catch((error) => {
        notifications.show({
          title: title,
          message: `Error updating quantities ${error.message}`,
          color: 'red',
          autoClose: 10000,
        });
      })
      .finally(() => {
        setStockChangeModal({ ...stockChangeModal, loading: false });
      });
  };

  const validateStockChangeUpdate = (apiResponse) => {
    const { data: resp } = apiResponse;
    const { item: newItem } = resp.data;

    if (resp.status === 'success' && resp.data) {
      setOrderList((prevOrderList) => ({
        ...prevOrderList,
        items: prevOrderList.items.map((item) => (item.id === newItem.id ? newItem : item)),
      }));
    } else {
      throw new Error('Failed to validate');
    }
  };

  const closeModal = () => {
    setStockChangeModal({ ...stockChangeModal, open: false });
  };

  useEffect(() => {
    setLoading(true);
    axiosInstance
      .get(`/dev/products/?page=${page}&limit=${PAGE_SIZE}`)
      .then((response) => {
        if (response.status === 200) {
          setOrderList(response.data.data);
        }
      })
      .catch((error) => {
        notifications.show({
          title: 'Error loading products',
          message: error.message,
          color: 'red',
          autoClose: 10000,
        });
      })
      .finally(() => {
        setLoading(false);
      });
  }, [page]);

  return (
    <Box>
      <Title order={2} mb={32}>
        My Products
      </Title>

      <Modal
        size="xl"
        opened={stockChangeModal.open}
        onClose={closeModal}
        title={stockChangeModal.title}
        className="stockChangeModal"
      >
        <Table striped highlightOnHover mb={16}>
          <Table.Thead>
            <Table.Tr>
              <Table.Th style={{ width: '100%' }}>SKU</Table.Th>
              {stockChangeModal.options &&
                stockChangeModal.options.map((option) => (
                  <Table.Th style={{ whiteSpace: 'nowrap', textTransform: 'capitalize' }} key={option.id}>
                    {option.name}
                  </Table.Th>
                ))}
              <Table.Th style={{ whiteSpace: 'nowrap' }}>Stock</Table.Th>
            </Table.Tr>
          </Table.Thead>
          <Table.Tbody>
            {stockChangeModal.data &&
              stockChangeModal.data.map((product, index) => (
                <Table.Tr key={product.sku}>
                  <Table.Td>{product.sku}</Table.Td>
                  {stockChangeModal.options.map((option, index) => (
                    <Table.Td key={index} style={{ whiteSpace: 'nowrap' }}>
                      {product[`option${option.position}`]}
                    </Table.Td>
                  ))}
                  <Table.Td style={{ whiteSpace: 'nowrap' }}>
                    <NumberInput
                      disabled={stockChangeModal.loading}
                      value={product.stock}
                      onChange={(value) => handleStockChange(value, index)}
                      min={0}
                      miw={125}
                      mw={125}
                    />
                  </Table.Td>
                </Table.Tr>
              ))}
          </Table.Tbody>
        </Table>
        <Box align="right">
          <Button loading={stockChangeModal.loading} color="blue" variant="filled" onClick={handleStockChangeSave}>
            Update Stock
          </Button>
        </Box>
      </Modal>

      <DataTable
        fetching={loading}
        withTableBorder
        withColumnBorders
        striped
        height={'100%'}
        style={{ minHeight: '60vh' }}
        columns={[
          {
            accessor: 'images',
            title: '',
            width: 70,
            render: (row) => {
              const firstImage = row.images[0];

              if (row.images && firstImage) {
                return (
                  <div
                    style={{
                      width: '50px',
                      height: '50px',
                      overflow: 'hidden',
                    }}
                  >
                    <img
                      src={firstImage.filepath}
                      alt={firstImage.filename}
                      style={{
                        width: '100%',
                        height: '100%',
                        objectFit: 'cover',
                      }}
                    />
                  </div>
                );
              } else {
                return null; // or some default image if no images are available
              }
            },
          },
          { accessor: 'name', title: 'Name', noWrap: true },
          { accessor: 'sku', title: 'SKU', noWrap: true },
          {
            accessor: 'variations',
            title: 'Variations',
            width: '100%',
            render: (row) => {
              const count = row.variations.length;

              if (count) {
                return <>{count}</>;
              } else {
                return null;
              }
            },
          },
          { accessor: 'stock', title: 'Quantity', noWrap: true },
          {
            accessor: 'actions',
            title: '',
            textAlign: 'right',
            render: (row) => (
              <Center>
                <Group gap={4} justify="right" wrap="nowrap">
                  <ActionIcon size="sm" variant="subtle" color="blue" onClick={() => showStockChangeModal(row)}>
                    <IconEdit size={16} />
                  </ActionIcon>
                </Group>
              </Center>
            ),
          },
        ]}
        records={orderList.items}
        recordsPerPage={PAGE_SIZE}
        page={page}
        totalRecords={orderList.pagination.totalItems}
        onPageChange={(p) => setPage(p)}
      />
    </Box>
  );
};

export default Products;
