import React from 'react';
import { Container, Flex, Group, Menu, rem } from '@mantine/core';
import { logout, getAuthUsername } from '../common/api';
import { useLocation } from 'react-router-dom';
import { IconLogout2, IconUser } from '@tabler/icons-react';

const Header = () => {
  const { pathname } = useLocation();
  const username = getAuthUsername();

  return (
    <div className="header">
      <Container size="xl">
        <Group justify="space-between" className="header__container">
          <a href="/">
            <img src="hw-partners-logo-light.svg" alt="Hertwill logo" width="310" height="25" />
          </a>
          <Flex
            gap="xl"
            justify="flex-start"
            align="flex-start"
            direction="row"
            wrap="wrap"
            mr={16}
            className="header__menu"
          >
            <a href="/" className={pathname === '/' ? 'active' : ''}>
              Orders
            </a>
            <a href="/products" className={pathname === '/products' ? 'active' : ''}>
              Products
            </a>
            <Menu shadow="md">
              <Menu.Target>
                <a
                  href="#logout"
                  onClick={(event) => {
                    event.preventDefault();
                  }}
                  style={{ display: 'inline-flex', gap: '5px', alignItems: 'center' }}
                >
                  <IconUser style={{ width: '16px', height: '16px', verticalAlign: 'middle' }} />
                  {username}
                </a>
              </Menu.Target>
              <Menu.Dropdown>
                <Menu.Item onClick={logout} leftSection={<IconLogout2 style={{ width: rem(14), height: rem(14) }} />}>
                  Log out
                </Menu.Item>
              </Menu.Dropdown>
            </Menu>
          </Flex>
        </Group>
      </Container>
    </div>
  );
};

export default Header;
